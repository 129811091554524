import Stripe from 'stripe';
import { json, redirect, type LoaderFunctionArgs } from '@remix-run/node';
import { loadStripe } from '@stripe/stripe-js';
import type { ClientLoaderFunctionArgs } from '@remix-run/react';
import PaymentForm from './PaymentForm';
import searchParamsSchema from './searchParamsSchema';
import PaymentFrequency from './PaymentFrequency';
import Background from '@/assets/images/donate-hero.png';
import serverEnv from '@/env.server';
import clientEnv from '@/env.client';
import db from '@/utils/db';
import PageWrapper from '@/components/app/PageWrapper';
import { shareCode } from '@/utils/cookies.server';
import queryClient from '@/services/queryClient';

const stripePromise = loadStripe(clientEnv?.VITE_APP_STRIPE_CLIENT_KEY, {
  stripeAccount: clientEnv?.VITE_APP_STRIPE_CONNECTED_ACCOUNT_ID,
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { searchParams } = url;
  let code = searchParams.get('code');
  const campaign = searchParams.get('campaign') ?? '';

  let shouldSetCookie = false;

  // if there is a campaign, use that as the "code" and let the approach follow through
  if (campaign && campaign !== '') {
    code = campaign;
    shouldSetCookie = true;
  }

  if (code) {
    try {
      await db.share.update({
        where: { code },
        data: {
          clicks: { increment: 1 },
        },
      });
      shouldSetCookie = true;
    } catch {
      console.log('failed');
    }
  }

  const stripe = new Stripe(serverEnv.STRIPE_SECRET_KEY);
  const { paymentFrequency, amount, sessionId, emoji } =
    searchParamsSchema.parse({
      paymentFrequency: searchParams.get('paymentFrequency'),
      amount: searchParams.get('amount'),
      sessionId: searchParams.get('sessionId'),
      emoji: searchParams.get('emoji'),
    });
  const isSubscription = paymentFrequency === PaymentFrequency.MONTHLY;
  const mode: Stripe.Checkout.SessionCreateParams.Mode | undefined =
    isSubscription ? 'subscription' : 'payment';

  const marketingMapping = new Map<string, string>([
    ['Gather25_Nov21ZoomCall', 'Gather25_Nov21MajorDonorZoomCall'],
    ['Gather25_Nov7FundraisingDinner', 'Gather25_Nov7FundraisingDinner'],
  ]);

  const { client_secret } = await stripe.checkout.sessions.create(
    {
      ui_mode: 'embedded',
      phone_number_collection: {
        enabled: false,
      },
      billing_address_collection: 'required',
      customer_creation: !isSubscription ? 'always' : undefined,
      payment_method_types: ['card', 'us_bank_account', 'link'],
      line_items: [
        {
          quantity: 1,
          price_data: {
            currency: 'usd',
            product_data: {
              name: 'Donation',
            },
            recurring:
              mode === 'subscription'
                ? {
                    interval: 'month',
                  }
                : undefined,
            unit_amount: amount,
          },
        },
      ],
      mode,
      payment_intent_data:
        mode === 'payment'
          ? {
              application_fee_amount: Math.round(
                Number((amount * 0.0179).toFixed(2)),
              ),
              statement_descriptor: 'WWW.IFGATHERING.COM',
              description:
                'The Gather Vision Fund supports the work that will allow us to reach 2.5B people around the world in prayer, repentance, and commissioning in 2025. Your gift will be used to provide assistance for the costs within the organization where it is needed most. IF Gathering is a 501(c)3 non-profit organization. No goods or services were received for contributions.',
              metadata: (() => {
                const metadata: Stripe.MetadataParam = {};
                if (marketingMapping.has(campaign)) {
                  metadata['marketingTag'] =
                    marketingMapping.get(campaign) ?? '';
                }
                return metadata;
              })(),
            }
          : undefined,
      subscription_data:
        mode === 'subscription'
          ? {
              application_fee_percent: 1.79,
              description:
                'The Gather Vision Fund supports the work that will allow us to reach 2.5B people around the world in prayer, repentance, and commissioning in 2025. Your gift will be used to provide assistance for the costs within the organization where it is needed most. IF Gathering is a 501(c)3 non-profit organization. No goods or services were received for contributions.',
              metadata: (() => {
                const metadata: Stripe.MetadataParam = {};
                if (marketingMapping.has(campaign)) {
                  metadata['marketingTag'] =
                    marketingMapping.get(campaign) ?? '';
                }
                return metadata;
              })(),
            }
          : undefined,
      submit_type: mode === 'payment' ? 'donate' : undefined,
      return_url: `${url.origin}?sessionId={CHECKOUT_SESSION_ID}&emoji=${emoji}`,
    },
    { stripeAccount: serverEnv.STRIPE_ACCOUNT_ID },
  );

  if (sessionId) {
    const session = await stripe.checkout.sessions.retrieve(sessionId, {
      stripeAccount: serverEnv.STRIPE_ACCOUNT_ID,
    });
    const splitName = session.customer_details?.name?.split(' ') ?? [];
    const firstNameAndInitial = `${splitName[0]} ${splitName[1].charAt(0)}.`;

    let cookieCode = null;

    try {
      const cookieHeader = request.headers.get('Cookie');
      cookieCode = await shareCode.parse(cookieHeader);
      if (cookieCode) {
        await db.share.update({
          where: { code: cookieCode },
          data: {
            amount_of_givers: { increment: 1 },
            amount_in_cents_donated: { increment: session.amount_subtotal! },
          },
        });
      }
    } catch {
      console.log('error updating share code givers and dollars');
    }

    const donation = {
      name: firstNameAndInitial,
      amount_in_cents: session.amount_subtotal,
      stripe_session_id: session.id,
      city: session.customer_details?.address?.city,
      emoji,
      campaign: cookieCode,
    };

    await db.donation.create({
      data: donation,
    });

    // Get the customer's name or leave it blank if not available
    const customerName = session.customer_details?.name
      ? encodeURIComponent(session.customer_details.name)
      : '';

    // Redirect to /thanks with or without the name query parameter
    return redirect(`/thanks${customerName ? `?name=${customerName}` : ''}`);
  }

  return json(
    {
      clientSecret: client_secret,
    },
    {
      headers: shouldSetCookie
        ? {
            'Set-Cookie': await shareCode.serialize(code),
          }
        : {},
    },
  );
};

export const clientLoader = async ({
  request,
  serverLoader,
}: ClientLoaderFunctionArgs) => {
  const url = new URL(request.url);
  const { searchParams } = url;
  const { paymentFrequency, amount } = searchParamsSchema.parse({
    paymentFrequency: searchParams.get('paymentFrequency'),
    amount: searchParams.get('amount'),
    sessionId: searchParams.get('sessionId'),
    emoji: searchParams.get('emoji'),
  });
  const { clientSecret } = await queryClient.ensureQueryData({
    queryKey: ['stripeClientSecret', amount, paymentFrequency],
    queryFn: () => serverLoader<{ clientSecret: string }>(),
  });

  return { clientSecret };
};

const DonatePage = () => {
  return (
    <PageWrapper>
      <div
        className="flex flex-col md:flex-row w-full h-full justify-center"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}
      >
        <h1
          className="text-4xl md:text-8xl mb-8 text-[#FFFFF0] md:max-w-[540px] p-8 font-general-sans-medium md:mr-8 md:mt-40"
          style={{ textShadow: '0px 0px 34.6px rgba(0, 0, 0, 0.55)' }}
        >
          Help Us Gather the Global Church
        </h1>
        <PaymentForm stripePromise={stripePromise} />
      </div>
    </PageWrapper>
  );
};

export default DonatePage;
